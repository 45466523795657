import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import { scaleDot, colors } from 'src/common/styles/index';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const DotContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Message = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Dot = styled.h1`
  width: 2rem;
  height: 2rem;
  margin: 1rem;
  margin-left: 0.1em;
  margin-right: 0.1em;
  font-size: 9em;
  line-height: 0.2em;
  background: ${colors.blue};
  animation: 1s infinite alternate ${scaleDot};
  animation-timing-function: ease-in-out;
  border-radius: 999px;
`;

const DotTwo = styled(Dot)`
  animation-delay: 0.12s;
`;

const DotThree = styled(Dot)`
  animation-delay: 0.24s;
`;

const DotFour = styled(Dot)`
  animation-delay: 0.36s;
`;

interface Props {
  content: string;
}

const Loading: FunctionComponent<Props> = ({ content }) => (
  <Container>
    <DotContainer>
      <Dot />
      <DotTwo />
      <DotThree />
      <DotFour />
    </DotContainer>
    <Message>
      <Typography variant="h5">{`Loading ${content}...`}</Typography>
    </Message>
  </Container>
);

export default Loading;
