import React, { FunctionComponent, Suspense } from 'react';
import Loading from 'src/common/components/Loading';
import styled from 'styled-components';

const AuthenticatedApp = React.lazy(() => import('./AuthenticatedApp'));

const LoadingWrapper = styled.div`
  height: 100vh;
`;

const App: FunctionComponent = () => {
  return (
    <Suspense
      fallback={
        <LoadingWrapper>
          <Loading content="QPP Application" />
        </LoadingWrapper>
      }
    >
      <AuthenticatedApp />
    </Suspense>
  );
};

export default App;
