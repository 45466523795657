import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import Cookies from 'js-cookie';

const { apiUrl } = window['runConfig'];
const httpLink = createHttpLink({
  uri: apiUrl,
});
const authLink = setContext((_, { headers }) => {
  const token = Cookies.get('authToken');
  return {
    headers: {
      ...headers,
      authorization: token ?? '',
    },
  };
});
const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((err) => {
      const code = err.extensions ? err.extensions.code : '';
      switch (code) {
        case 'UNAUTHENTICATED':
          Cookies.remove('authToken');
          Cookies.set('authAttempts', '0');
          Cookies.remove('authProgress');
          Cookies.remove('authError');
        // window.location.assign(ssoUrl);
      }
    });
  }
});
const link = ApolloLink.from([authLink, errorLink, httpLink]);
const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

client.defaultOptions = {
  watchQuery: {
    fetchPolicy: 'network-only',
    errorPolicy: 'ignore',
    notifyOnNetworkStatusChange: true,
  },
  query: {
    fetchPolicy: 'cache-first',
    errorPolicy: 'all',
  },
};

export default client;
