import { keyframes } from 'styled-components';

export const colors = {
  blue: '#0427F6',
  darkBlue: '#3f51b5',
  red: '#EE0018',
  black: '#000',
  white: '#FFF',
  lightGray: '#979797',
  alabaster: '#FBFBFB',
};

export const boxShadows = {
  basic: '0 12px 24px 0 rgba(0, 0, 0, 0.3)',
  inner: 'inset 0 0 10px black',
  bottomRight: '5px 5px 10px black',
  topLeft: '-5px -5px 10px black',
  blurRadius: '5px 5px 20px black',
  negativeSpread: '0 10px 10px -5px black',
};

export const breakpoints = {
  mobile: 790,
};

// eslint-disable-next-line
export const blinkingText = (color: string): any => keyframes`
  0% {
    color: ${color};
    border: 1px solid ${color};
  }
  49% {
    color: ${color};
    border: 1px solid ${color};
  }
  60% {
    color: transparent;
    border-color: transparent;
  }
  99% {
    color: transparent;
    border-color: transparent;
  }
  100% {
    color: ${color};
    border: 1px solid ${color};
  }
`;

export const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

export const growIn = keyframes`
  from { transform: scale(0); }
  to { transform: scale(1); }
`;

export const rotate360 = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

export const showHideDot = keyframes`
  0% { opacity: 0; }
  50% { opacity: 1; }
  60% { opacity: 1; }
  100% { opacity: 0; }
`;

export const scaleDot = keyframes`
  0% { transform: scale(1); }
  100% { transform: scale(.3); }
`;
