import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import { ApolloProvider } from '@apollo/client';
import client from './graphql';
import CssBaseline from '@material-ui/core/CssBaseline';
import App from './pages/App';

import * as serviceWorker from './serviceWorker';

declare global {
  interface Window {
    // eslint-disable-next-line
    [key: string]: any;
  }
}

const GlobalStyle = createGlobalStyle`
  body {
    padding: 0;
    margin: 0;
    height: 100vh;
    font-family: 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    box-sizing: border-box;

    a {
      text-decoration: none;
    }

    sub {
      line-height: 0;
    }
  }
`;

ReactDOM.render(
  <Router>
    <ApolloProvider client={client}>
      <GlobalStyle />
      <CssBaseline />
      <App />
    </ApolloProvider>
  </Router>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
